import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {
  MiscCalculationStrategyFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Application/Factory/misc-calculation-strategy-factory.service";
import {
  EditMiscOptionNameModelComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/edit-misc-option-name-model/edit-misc-option-name-model.component";
import {
  MiscAreaComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/misc-area/misc-area.component";
import {
  MiscTotalComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/misc-total/misc-total.component";
import {
  AddCalculationStrategyCommand
} from "@modules/calculation-strategy/Application/UseCase/Command/add-calculation-strategy.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";

export const MISC_CALCULATION_ID = CalculationStrategyID.fromString('miscellaneous');

@NgModule({
  declarations: [
    MiscAreaComponent,
    MiscTotalComponent,
    EditMiscOptionNameModelComponent
  ],
  exports: [
    MiscAreaComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    ClearZeroDirective
  ]
})
export class MiscellaneousCalculatorModule {
  constructor(
    addCalculationStrategyCommand: AddCalculationStrategyCommand,
    strategyFactory: MiscCalculationStrategyFactory
  ) {
    const strategy = strategyFactory.execute();
    addCalculationStrategyCommand.execute(strategy).then();
  }
}
