import {Injectable} from "@angular/core";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({
  providedIn: 'root'
})
export class CalculatorAreaLinkProvider {
  public static readonly BASE_URL = 'area';

  public static getMiscAreaLink(calculationID: CalculationID): string[] {
    return [
      ...CalculatorAreaLinkProvider.getBaseUrl(),
      'misc',
      calculationID.toString()
    ];
  }

  private static getBaseUrl(): string[] {
    return ['/', CalculatorAreaLinkProvider.BASE_URL];
  }
}
