import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class MiscCalculationItemsService {
  private newItemSubject = new BehaviorSubject<boolean>(false);
  newItems$ = this.newItemSubject.asObservable();

  addNewItem(): void {
    this.newItemSubject.next(true);
  }
}
